/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hi! I'm a software developer, and I work with web, mobile and data engineering technologies. I also enjoy making animation videos with Adobe After Effects. My main software focus areas are:", React.createElement("br")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Typescript / Javascript"), "\n", React.createElement(_components.li, null, "React Native"), "\n", React.createElement(_components.li, null, "React / Gatsby / Next.js"), "\n", React.createElement(_components.li, null, "Ruby / Rails"), "\n", React.createElement(_components.li, null, "Data Engineering (SQL, DBT)"), "\n", React.createElement(_components.li, null, "Serverless / Firebase"), "\n", React.createElement(_components.li, null, "Elasticsearch"), "\n"), "\n", React.createElement(_components.p, null, "I'm also interested in the startup process, in particular, how to reduce the cost and time that it takes to learn on the way to building something great. I recommend ", React.createElement(_components.a, {
    href: "https://leanstack.com/"
  }, "Lean Stack"), " for those interested in the science of successful startups."), "\n", React.createElement(_components.p, null, "I'm a member of the Collaborative VC ", React.createElement(_components.a, {
    href: "https://hitchhiker.vc"
  }, "Hitchhiker"), " - we invest in amazing teams and game-changing ventures. I'm always interested in hearing about interesting projects that people are working on. Feel free to ", React.createElement(_components.a, {
    href: "mailto:hi@michaeldawson.com.au"
  }, "drop me a line!")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
